import React from "react"
import get from "lodash.get"
import BoxedLayout from "./../components/layouts/BoxedLayout"
import Seo from "../components/seo"
import PostBlock from "../components/PostBlock"
import PostsContainer from "./../components/PostsContainer"

export default function Template(props) {
  const {
    pageContext: {
      posts,
    }
  } = props;

  return (
    <BoxedLayout>
      <Seo title="Tzookb - My Work" />
      <div className="blog-post-container">
        <div className="blog-post">
          <h3>My Work</h3>
          <PostsContainer>
            {posts.map(node => {
              const url = get(node, 'frontmatter.url')
              return (
                <div
                  key={url}
                  >
                  <PostBlock
                    {...node}
                    />
                </div>
              )
            })}
          </PostsContainer>
        </div>
      </div>
    </BoxedLayout>
  )
}
